import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./SelectCrypto.module.sass";
import Icon from "../Icon";

import { articles } from "../../mocks/articles";

const SelectCrypto = ({ goNext }) => {
  const [search, setSearch] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <div className={styles.crypto}>
      <div className={styles.title}>Select project</div>
      <form className={styles.form} action="" onSubmit={() => handleSubmit()}>
        <input
          className={styles.input}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search projects"
          required
        />
        <button className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">#</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Name</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Price</div>
          </div>
          <div className={styles.col}>Available Slots</div>
          <div className={styles.col}>Info</div>
        </div>
        {articles.map((x, index) => (
          <div className={styles.row} key={index} onClick={goNext}>
            <div className={styles.col}>
              <div className={styles.line}>
                <button className={cn("favorite", styles.favorite)}>
                  <Icon name="star-outline" size="16" />
                </button>
                {index + 1}
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.item}>
                <div className={styles.icon}>
                  <img src={x.image} alt="Coin" />
                </div>
                <div className={styles.details}>
                  <span className={styles.subtitle}>{x.title}</span>
                </div>
              </div>
            </div>

            <div className={styles.col}>{x.statusText}</div>
            <div className={styles.col}>
              <div className={styles.positive}>{x.available}</div>
            </div>
            <div className={styles.col}>
              <Link
                to={`/project-details/${index}`}
                className={cn("button-stroke button-small", styles.button)}
              >
                More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectCrypto;
