import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const items = [
  {
    title: "Non-Profits",
    value: "RM0.00",
    percentage: "2.00%",
    projects: "0",
  },
  {
    title: "Assets purchase",
    value: "RM116,000.00",
    percentage: "2.00%",
    projects: "3",
  },
  {
    title: "Business",
    value: "RM0.00",
    percentage: "0.00%",
    projects: "0",
  },
];

const Main = () => {
  return (
    <div className={styles.main}>
      <h4 className={cn("h4", styles.title)}>Participated Projects</h4>
      <div className={styles.list}>
        {items.map((x, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.info}>{x.title}</div>
            <div className={styles.line}>
              <div className={styles.currency}>{x.value}</div>
              <div className={cn("category-green", styles.category)}>
                {x.percentage}
              </div>
            </div>
            <div className={styles.price}>{x.projects} projects</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Main;
