import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink } from "react-router-dom";
import Image from "../Image";
import Dropdown from "./Dropdown";
import Settings from "./Settings";
import Icon from "../Icon";
import Notifications from "./Notifications";
import Theme from "../Theme";
import User from "./User";

const Header = ({ headerWide }) => {
  const [visibleNav, setVisibleNav] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const savedValue = window.localStorage.getItem("isAuthenticated");
    if (savedValue === "true") {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  });

  return (
    <header className={cn(styles.header, { [styles.wide]: headerWide })}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className={styles.picDesktop}
            src="/images/logo-black.png"
            srcDark="/images/logo-white.png"
            alt="BitCloud"
          />
          <img
            className={styles.picMobile}
            src="/images/logo-black.png"
            alt="BitCloud"
          />
        </Link>
        <div className={styles.wrapper}>
          <div
            className={cn(styles.wrap, { [styles.visible]: visibleNav })}
          ></div>
          {isAuthenticated ? (
            <>
              <Settings className={styles.settings} />
              <div className={styles.control}>
                <NavLink
                  className={styles.activity}
                  activeClassName={styles.active}
                  to="/activity"
                >
                  <Icon name="lightning" size="24" />
                </NavLink>
                <Notifications className={styles.notifications} />
                <NavLink
                  className={cn("button-stroke button-small", styles.button)}
                  activeClassName={styles.active}
                  to="/accounts"
                >
                  My Accounts
                </NavLink>
                <Theme className={styles.theme} icon />
                <User className={styles.user} />
              </div>
            </>
          ) : (
            <div className={styles.btns}>
              <Link
                className={cn("button-small", styles.button)}
                activeClassName={styles.active}
                to="/sign-up"
                onClick={() => setVisibleNav(false)}
              >
                Sign Up
              </Link>
              <Link
                className={cn("button-stroke button-small", styles.button)}
                activeClassName={styles.active}
                to="/sign-in"
                onClick={() => setVisibleNav(false)}
              >
                Login
              </Link>
            </div>
          )}
          <button
            className={cn(styles.burger, { [styles.active]: visibleNav })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </div>
    </header>
  );
};

export default Header;
