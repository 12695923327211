import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./AssetBalances.module.sass";

const items = [
  {
    id: 0,
    title: "TNB Solar",
    content: "4.5 kWp panel",
    icon: "/images/icons/money.png",
    earn: "Purchased",
    amount: "2",
    value: "RM 20,000.00",
    type: "Fiat",
  },
  {
    id: 1,
    title: "PROTON",
    content: "X-50 (2021)",
    icon: "/images/icons/vehicles.png",
    earn: "Transfered",
    amount: "1",
    value: "RM 85,000.00",
    type: "Vehicles",
  },
  {
    id: 1,
    title: "PROTON",
    content: "Persona (2007)",
    icon: "/images/icons/vehicles.png",
    earn: "Transfered",
    amount: "1",
    value: "RM 11,000.00",
    type: "Vehicles",
  },
];

const AssetBalances = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Asset</div>
          <div className={styles.col}>Action</div>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Value</div>
          <div className={styles.col}>Account Type</div>
        </div>
        {items.map((x, index) => (
          <Link
            className={styles.row}
            to={`/wallet-overview/${x.id}`}
            key={index}
          >
            <div className={styles.col}>
              <div className={styles.currency}>
                <div className={styles.icon}>
                  <img src={x.icon} alt="Currency" />
                </div>
                <div className={styles.details}>
                  <div className={styles.info}>{x.title}</div>
                  <div className={styles.text}>{x.content}</div>
                </div>
              </div>
            </div>
            <div className={styles.col}>
              {x.earn && (
                <div className={cn("category-green", styles.category)}>
                  {x.earn}
                </div>
              )}
            </div>
            <div className={styles.col}>
              <div className={styles.info}>{x.amount}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.info}>{x.value}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.info}>{x.type}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AssetBalances;
