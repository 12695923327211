import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Integrations.module.sass";
import Icon from "../../../components/Icon";

const Integrations = () => {
  const items = [
    {
      title: "Altruist DAO",
      content:
        "Make recurring contribution on to our community account and get the chance to vote on our next community project",
      name: "altruistsdao",
      price: "RM300",
      frequency: "Monthly",
      activated: true,
    },
    {
      title: "Patron of Hidayah Centre",
      content:
        "Recurring contribution to Hidayah Centre to support their cause",
      name: "altruistsdao",
      price: "RM10",
      frequency: "Monthly",
      activated: false,
    },
  ];

  return (
    <div className={styles.list}>
      {items.map((x, index) => (
        <div className={styles.item} key={index}>
          <div className={styles.head}>
            <div className={styles.title}>{x.title}</div>
            <div className={styles.content}>{x.content}</div>
          </div>
          <div className={styles.body}>
            <div className={styles.fee}>
              {x.price}/{x.frequency}
            </div>
            <div>
              <span className={styles.line}>
                {x.activated && (
                  <div className={cn("category-stroke-green", styles.status)}>
                    SUBSCRIBED
                  </div>
                )}
                <span className={styles.text}>
                  <Icon name="line-dots" size="16" />
                  {x.activated ? (
                    <span>Deactivate</span>
                  ) : (
                    <Link to="/membership-contribution">
                      {" "}
                      <span>Activate</span>
                    </Link>
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Integrations;
