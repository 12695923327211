import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";
import Share from "../../../components/Share";
import Favorite from "../../../components/Favorite";

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <h3>Introduction</h3>
          <p>
            By participating in this project you will purchase a 4.5KW solar
            panel and donate it to house. Throught NEM, energy produced by the
            donated solar panel will be sell to TNB and the proceeds will then
            distributed to beneficiaries of your choice.
          </p>
          <h3>Projected Profit</h3>
          <p>
            This estimated profit for this project should be around RM 200.00
            Per Month
          </p>
          <figure>
            <img src="/images/projects/solaroof.jpeg" alt="Content" />
          </figure>
          <h3>Terms and Conditions</h3>
          <p>
            Project profits are not guranteed thus in case of the solar panel is
            damaged, proceeds will no longer received
          </p>
        </div>
        <div className={styles.actions}>
          <Share openUp />
          <Favorite className={styles.favorite} />
        </div>
      </div>
    </div>
  );
};

export default Article;
