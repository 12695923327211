import React, { useState } from "react";
import parse from "html-react-parser";

const Submitted = ({ msg, title }) => {
  return (
    <div>
      {parse(
        `<lottie-player src="/images/lottie/sms-sent.json"  background="transparent"  speed="1"  style="width: 400px; height: 300px;" autoplay></lottie-player>`
      )}
      <h3 className="text-center">{title}</h3>
      <p className="text-muted text-center">{msg}</p>
    </div>
  );
};

export default Submitted;
