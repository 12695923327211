import React, { useState } from "react";
import cn from "classnames";
import styles from "./SelectCurrency.module.sass";
import Dropdown from "../../../components/Dropdown";

const durationOptions = ["One Year", "Two Year", "Three Year"];
const frequencyOptions = ["Daily", "Weekly", "Monthly"];
const priceVariants = ["100", "200", "300", "500", "1000"];

const SelectCurrency = ({ goNext }) => {
  const [duration, setDuration] = useState(durationOptions[0]);
  const [frequency, setFrequency] = useState(frequencyOptions[0]);
  const [price, setPrice] = useState("0");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <form className={styles.item} action="" onSubmit={() => handleSubmit()}>
      <div className={styles.title}>Patron of Hidayah Centre</div>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>Select Duration</div>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={duration}
            setValue={setDuration}
            options={durationOptions}
          />
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Select Frequency</div>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={frequency}
            setValue={setFrequency}
            options={frequencyOptions}
          />
        </div>
      </div>
      <div className={styles.label}>Enter Contribution Amount</div>
      <div className={styles.payment}>
        <div className={cn("h4", styles.sign)}>RM</div>
        <div className={styles.field}>
          <div className={styles.value}>{price}</div>
          <input
            className={styles.input}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.variants}>
        {priceVariants.map((x, index) => (
          <button
            className={cn("button-stroke button-small", styles.button)}
            type="button"
            onClick={() => setPrice(x.replace("", ""))}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={goNext}>
          Continue
        </button>
      </div>
    </form>
  );
};

export default SelectCurrency;
