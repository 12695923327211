import React, { useState } from "react";
import Main from "./Main";
import styles from "./Membership.module.sass";
import Wallet from "../../components/Wallet";
import Integrations from "./Integrations";

const optionsCurrency = ["USD", "EUR", "RUB"];

const WalletOverview = () => {
  return (
    <Wallet>
      <Main />
      <div>
        <div className={styles.item}>
          <div className={styles.head}>Memberships Available</div>
          <div className={styles.body}>
            <Integrations />
          </div>
        </div>
      </div>
    </Wallet>
  );
};

export default WalletOverview;
