import React, { useState } from "react";
import cn from "classnames";
import styles from "./PaymentInfo.module.sass";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";

const cards = [
  {
    image: "./images/content/visa.svg",
    alt: "Visa",
  },
  {
    image: "./images/content/master-card.svg",
    alt: "Master Card",
  },
];

const PaymentInfo = ({ goNext, goBack }) => {
  const [save, setSave] = useState(true);

  return (
    <form className={styles.item}>
      <div className={styles.control}>
        <button className={styles.back} onClick={goBack}>
          <Icon name="arrow-prev" size="14" />
          Payment info
        </button>
        <div className={styles.money}>
          TNB Solar Panel
          <img src="/images/icons/money.png" alt="project" />
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.subtitle}>Credit Card</div>
        <div className={styles.cards}>
          {cards.map((x, index) => (
            <div className={styles.card} key={index}>
              <img src={x.image} alt={x.alt} />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="card number"
          name="card"
          type="tel"
          placeholder="XXXX XXXX XXXX XXXX"
          required
        />
        <TextInput
          className={styles.field}
          label="card holder"
          name="holder"
          type="text"
          placeholder="TRAN MAU TRI TAM"
          required
        />
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="EXPIRATION DATE"
            name="date"
            type="tel"
            placeholder="MM / YY"
            required
          />
          <TextInput
            className={styles.field}
            label="CVC"
            name="cvc"
            type="tel"
            placeholder="CVC"
            required
          />
        </div>
      </div>
      <Checkbox
        className={styles.checkbox}
        value={save}
        onChange={() => setSave(!save)}
        content="Save Card"
      />
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={goNext}>
          Make Payment
        </button>
      </div>
    </form>
  );
};

export default PaymentInfo;
