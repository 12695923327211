import React, { useState } from "react";
import cn from "classnames";
import styles from "./EnterAmount.module.sass";
import Icon from "../../../components/Icon";

const priceVariants = ["1", "2", "3", "4", "5"];

const EnterAmount = ({ goNext, goBack }) => {
  const [price, setPrice] = useState("10,000");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <form className={styles.item} action="" onSubmit={() => handleSubmit()}>
      <div className={styles.control}>
        <button className={styles.back} onClick={goBack}>
          <Icon name="arrow-prev" size="14" />
          Enter amount
        </button>
        <div className={styles.money}>
          TNB Solar Panel
          <img src="/images/icons/money.png" alt="project" />
        </div>
      </div>
      <div className={styles.payment}>
        <div className={cn("h4", styles.sign)}>RM</div>
        <div className={styles.field}>
          <div className={styles.value}>{price}</div>
          <input className={styles.input} value={price} />
        </div>
      </div>
      <div className={styles.price}>
        <span>Project Quantity</span>
      </div>
      <div className={styles.variants}>
        {priceVariants.map((x, index) => (
          <button
            className={cn("button-stroke button-small", styles.button)}
            type="button"
            key={index}
            onClick={() => setPrice((Number(x) * 10000).toLocaleString())}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={goNext}>
          Continue
        </button>
      </div>
    </form>
  );
};

export default EnterAmount;
