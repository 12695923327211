import React, { useState } from "react";
import cn from "classnames";
import styles from "./Funds.module.sass";
import Icon from "../Icon";
import Item from "./Item";

const items = [
  {
    currency: "Ahmad Zahid",
    content: "No 19, Jalan Kuchai Lama, Kuala lumpur",
    icon: "/images/content/avatar-user.jpg",
    currencyTotal: "909090-09-0909",
    priceTotal: "Malaysia",
    currencyAvailable: "RM1,000.00",
    priceAvailable: "Ringgit Malaysia",
    currencyInterest: "Brother",
    priceInterest: "Individual",
  },
];

const Funds = ({ children }) => {
  const [search, setSearch] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>
        <form className={styles.form} action="" onSubmit={() => handleSubmit()}>
          <input
            className={styles.input}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            name="search"
            placeholder="Search coin"
            required
          />
          <button className={styles.result}>
            <Icon name="search" size="20" />
          </button>
        </form>
        <button className={styles.link}>
          <span>Borrowing history</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Identity Number</div>
          <div className={styles.col}>Payout Received</div>
          <div className={styles.col}>Relation</div>
        </div>
        {items.map((x, index) => (
          <Item
            className={styles.item}
            item={x}
            key={index}
            children={children}
          />
        ))}
      </div>
    </div>
  );
};

export default Funds;
