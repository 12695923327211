import React from "react";
import cn from "classnames";
import styles from "./Popular.module.sass";
import Slider from "react-slick";
import Icon from "../Icon";
import Card from "./Card";
import Catalog from "./Catalog";

const items = [
  {
    title: "Equity Investment",
    content:
      "Purchase stocks and transfer ownership to us. Any profits derived from the donated stocks will be split between us and beneficiaries",
    button: "Buy crypto",
    image: "/images/3d/trading.png",
    image2x: "/images/3d/trading.png",
    url: "/buy-crypto",
  },
  {
    title: "Vehicle Leasing",
    content:
      "Lease your vehicle with flux. Rental derived from the will be split between us and beneficiaries",
    button: "Trade now",
    image: "/images/3d/car.png",
    image2x: "/images/3d/car.png",
    url: "/exchange",
  },
  {
    title: "Solar Panel",
    content:
      "Purchase a solar panel and donate it. Generated electricity will then sell to TNB and proceeds will be split between us and beneficiaries",
    button: "Learn now",
    image: "/images/3d/compass.png",
    image2x: "/images/3d/compass.png",
    url: "/learn-crypto",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Popular = ({ classSection }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: false,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
    ],
  };

  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>Available Projects</h2>
          <div className={styles.info}>
            We've prepared interesting projects that you can start to contribute
            and build up your trust account balance.
          </div>
        </div>
        <div className={styles.wrapper}>
          <Catalog />
        </div>
      </div>
    </div>
  );
};

export default Popular;
