import React from "react";
import cn from "classnames";
import styles from "./Code.module.sass";
import Submitted from "../../../components/Custom/Submitted";

const Code = () => {
  return (
    <form className={styles.form}>
      <h3 className={cn("h3", styles.title)}>Enter your security code</h3>
      <Submitted title="Registration succeeded" msg="We texted your code" />
      <div className={styles.info}></div>
      <div className={styles.code}>
        <div className={styles.number}>
          <input type="tel" />
        </div>
        <div className={styles.number}>
          <input type="tel" />
        </div>
        <div className={styles.number}>
          <input type="tel" />
        </div>
        <div className={styles.number}>
          <input type="tel" />
        </div>
      </div>
    </form>
  );
};

export default Code;
