import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = () => {
  return (
    <div className={styles.main}>
      <h4 className={cn("h4", styles.title)}>Beneficiaries</h4>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.details}>
            <div className={styles.info}>Total payout Value</div>
            <div className={styles.currency}>
              <div className={styles.number}>RM 1,000.00</div>
              <div className={cn("category-green", styles.category)}>
                +2.00%
              </div>
            </div>
            <div className={styles.price}>
              *as of {new Date().toLocaleString()}
            </div>
          </div>
        </div>
        <div className={cn(styles.item, styles.flex)}>
          <div className={styles.chart}>
            <img src="/images/content/chart.svg" alt="Chart" />
          </div>
          <div className={styles.details}>
            <div className={styles.info}>Active Beneficiaries</div>
            <div className={styles.currency}>
              <div className={styles.number}>1</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
