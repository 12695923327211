import React, { useState } from "react";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Icon from "../Icon";

const socials = [
  {
    title: "facebook",
    size: "16",
    url: "https://www.facebook.com",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com",
  },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © {new Date().getFullYear()} Salesbyte Global PLT. All
            rights reserved
          </div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
