import React from "react";
import cn from "classnames";
import styles from "./PaymentDetails.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";

const items = [
  {
    title: "Organisation",
    content: "Patron of Hidayah Centre",
  },
  {
    title: "Name",
    content: "Ahmad Albab",
  },
  {
    title: "Address",
    content: "A-12-D, Apartment, Kuala Lumpur",
  },
  {
    title: "Contribution Amount",
    content: "RM330/month",
  },
  {
    title: "Duration",
    content: "One Year",
  },
];

const PaymentDetails = () => {
  return (
    <div className={styles.item}>
      <div className={styles.title}>Summary</div>
      <div className={styles.info}>
        You Succesfully Subscribed to the Membership
      </div>
      <div className={styles.list}>
        {items.map((x, index) => (
          <div className={styles.line} key={index}>
            <div className={styles.subtitle}>{x.title}</div>
            <div className={styles.details}>
              <div className={styles.content}>{x.content}</div>
              <button className={styles.copy}>
                <Icon name="copy" size="24" />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.info}>Membership Code</div>
      <div className={styles.text}>
        This is yout unique Id to indicate you are having active membership.
        Please use this code to claim benefits if provided by the organisation.
      </div>
      <div className={styles.code}>BLUTUKHY34PB</div>
      <div className={styles.btns}>
        <Link className={cn("button-stroke", styles.button)} to="/memberships">
          View Memberships
        </Link>
      </div>
    </div>
  );
};

export default PaymentDetails;
