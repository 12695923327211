import React, { useState } from "react";
import cn from "classnames";
import styles from "./WalletOverview.module.sass";
import Wallet from "../../components/Wallet";
import { Doughnut } from "react-chartjs-2";
import AccountBalances from "./AccountBalances";
import AssetBalances from "./AssetBalances";

const optionsCurrency = ["USD", "EUR", "RUB"];

const WalletOverview = () => {
  const [data, setData] = useState({
    labels: ["Fiat", "Crypto", "Vehicles", "Real Estates"],
    datasets: [
      {
        label: "My First Dataset",
        data: [20000, 0, 96000, 0],
        backgroundColor: ["#FFC93C", "#16697A", "#C1DEAE", "#EDF5E1"],
        hoverOffset: 1,
      },
    ],
  });

  return (
    <Wallet>
      <div className={styles.top}>
        <h4 className={cn("h4", styles.title)}>Account Summary</h4>
        <div className={styles.line}>
          <div>
            <div className={styles.info}>Total Contribution Value</div>
            <div className={styles.currency}>
              <h4 className={cn("h4", styles.title)}>RM 116,000.00</h4>
              <div className={cn("category-green", styles.category)}>
                +2.00%
              </div>
            </div>
            <div className={styles.price}>
              *as of {new Date().toLocaleString()}
            </div>
          </div>
          <div>
            <Doughnut
              style={{ width: 275 }}
              options={{
                plugins: {
                  legend: {
                    position: "bottom",
                    labels: {
                      padding: 12,
                    },
                  },
                },
              }}
              data={data}
            />
          </div>
        </div>
      </div>
      <div>
        <div className={styles.item}>
          <div className={styles.head}>Account Balances</div>
          <div className={styles.body}>
            <AccountBalances />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.head}>Recent Transactions</div>
          <div className={styles.body}>
            <AssetBalances />
          </div>
        </div>
      </div>
    </Wallet>
  );
};

export default WalletOverview;
