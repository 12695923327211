import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Catalog.module.sass";
import Card from "../../../components/Card";

// data
import { articles } from "../../../mocks/articles";

const Catalog = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.list}>
          {articles.map((x, index) => (
            <Card className={styles.card} item={x} key={index} />
          ))}
        </div>
        <div className={styles.btns}>
          <Link
            to="/projects"
            className={cn("button-stroke button-small", styles.button)}
          >
            View More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
