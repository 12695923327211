import React, { useState } from "react";
import cn from "classnames";
import styles from "./ConfirmOrder.module.sass";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Successfully from "./Successfully";

const options = [
  {
    title: "Project",
    content: "TNB Solar",
    color: "#9757D7",
    icon: "wallet",
  },
  {
    title: "Price",
    content: "RM10,000",
    color: "#58BD7D",
    icon: "wallet",
  },
  {
    title: "Method",
    content: "Credit card",
    color: "#4BC9F0",
    icon: "wallet",
  },
];

const parameters = [
  {
    title: "Beneficiaries",
    content: "Assigned to Project",
  },
  {
    title: "Ahmad Zahid",
    content: "Active",
  },
];

const ConfirmOrder = ({ goBack, goStart }) => {
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false);

  return (
    <>
      <div className={styles.item}>
        <div className={styles.control}>
          <button className={styles.back} onClick={goBack}>
            <Icon name="arrow-prev" size="14" />
            Summary
          </button>
        </div>
        <div className={styles.info}>
          Your payment for<span>TNB Solar panel</span> project was Success!
        </div>
        <div className={styles.options}>
          {options.map((x, index) => (
            <div className={styles.option} key={index}>
              <div className={styles.icon} style={{ backgroundColor: x.color }}>
                <Icon name={x.icon} size="20" />
              </div>
              <div className={styles.details}>
                <div className={styles.category}>{x.title}</div>
                <div className={styles.content}>{x.content}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.table}>
          {parameters.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.cell}>{x.title}</div>
              <div className={styles.cell}>{x.content}</div>
            </div>
          ))}
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button", styles.button)}
            onClick={() => setVisibleSuccessfully(true)}
          >
            Confirm
          </button>
        </div>
      </div>
      <Modal
        visible={visibleSuccessfully}
        onClose={() => setVisibleSuccessfully(false)}
      >
        <Successfully />
      </Modal>
    </>
  );
};

export default ConfirmOrder;
