import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";

const items = [
  {
    title: "Create account",
    image2x: "/images/content/step-4@2x.png",
    image: "/images/content/step-4.png",
    content: "Sign up for your virtual trust account in under 90 seconds.",
  },
  {
    title: "Contribute assets",
    image2x: "/images/content/step-3@2x.png",
    image: "/images/content/step-3.png",
    content: "Contribute your assets or make cash donation to a project",
  },
  {
    title: "Choose beneficiaries",
    image2x: "/images/content/step-2@2x.png",
    image: "/images/content/step-2.png",
    content: "Named people or group to receive profit derived from the assets",
  },
  {
    title: "Track Contribution",
    image2x: "/images/content/step-1@2x.png",
    image: "/images/content/step-1.png",
    content: "Monitor your total contribution and distribution in real time",
  },
];

const Steps = ({ classSection, scrollToRef }) => {
  return (
    <div className={cn(classSection, styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>How it works</h2>
          <div className={styles.info}>
            Our trust accounts separate your giving journey into simple steps,
            making it simple to purposefully give RM 100 or RM 1 Mil—right away,
            or over a lifetime.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.preview}>
                <img
                  srcSet={`${x.image2x} 2x`}
                  src={x.image}
                  alt={`Step ${index + 1}`}
                />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
