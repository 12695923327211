import React, { useState } from "react";
import cn from "classnames";
import styles from "./Transfer.module.sass";
import Icon from "../Icon";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import { Link } from "react-router-dom";

const fromDirectionOptions = ["Margin", "Fiat and Spot"];
const toDirectionOptions = ["Fiat and Spot", "Margin"];
const coinOptions = ["ETH Ethereum", "BTC Bitcoin", "TRX Tron"];

const Transfer = () => {
  const [fromDirection, setFromDirection] = useState(fromDirectionOptions[0]);
  const [toDirection, setToDirection] = useState(toDirectionOptions[0]);
  const [coin, setCoin] = useState(coinOptions[0]);

  return (
    <div className={styles.transfer}>
      <div className={cn("h4", styles.title)}>
        <Icon name="arrow-left" size="32" />
        Assigned Projects
      </div>
      <div className={styles.price}>TNB Solar Panel</div>
      <div className={styles.wrap}>
        <div className={styles.category}>Total Payout</div>
        <div className={styles.details}>
          <div className={styles.currency}>RM 500.00</div>
          <div className={styles.price}>Ringgit Malaysia</div>
        </div>
      </div>
      <div className={styles.price}>Doby Machine</div>
      <div className={styles.wrap}>
        <div className={styles.category}>Total Payout</div>
        <div className={styles.details}>
          <div className={styles.currency}>RM 500.00</div>
          <div className={styles.price}>Ringgit Malaysia</div>
        </div>
      </div>
      <Link className={cn("button", styles.button)} to="projects">
        Add More
      </Link>
    </div>
  );
};

export default Transfer;
