export const articles = [
  {
    title: "TNB Solar Panel",
    author: "Fatimah Hassan",
    category: "green",
    categoryText: "Asset Purchase",
    status: "green",
    statusText: "RM 10,000",
    image: "/images/projects/solar.jpeg",
    play: true,
    available: 29,
    url: "/project-details/1",
  },
  {
    title: "Proton Perdana (2012)",
    author: "Chew Szer Ker",
    category: "green",
    categoryText: "Asset Purchase",
    status: "green",
    statusText: "RM 17,000",
    image: "/images/projects/proton.jpg",
    available: 1,
    url: "#",
  },
  {
    title: "Nestle Bakul Makananan",
    author: "Kugen Sengkuttavan",
    category: "purple",
    categoryText: "Non Profit",
    status: "green",
    statusText: "RM 100",
    image: "/images/projects/nestle.jpg",
    available: 99,
    url: "#",
  },
  {
    title: "Diet Cookies",
    author: "Mandy Smith",
    category: "blue",
    categoryText: "Business",
    status: "green",
    statusText: "RM 100",
    image: "/images/projects/cookie.jpeg",
    play: true,
    available: 3,
    url: "#",
  },
];
