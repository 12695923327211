import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Main.module.sass";
import Cards from "./Cards";

const Main = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h3", styles.title)}>
            Charity made easy with<br></br> a personal donation account.
          </h1>
          <div className={styles.text}>
            Philanthropy isn't just for the rich. Start giving now by
            registering account with us and keep track of your donation.
          </div>
          <Link
            className={cn("button", styles.button)}
            onClick={() => {
              localStorage.setItem("isAuthenticated", false);
            }}
            to="/sign-in"
          >
            Get started now
          </Link>
          {/* <ScrollButton
            onScroll={() =>
              scrollToRef.current.scrollIntoView({ behavior: "smooth" })
            }
            className={styles.scroll}
          /> */}
        </div>
        <div className={styles.bg}>
          <img width="192" src="/images/3d/crypto-wallet.png" alt="Cards" />
        </div>
        <Cards className={styles.cards} />
      </div>
    </div>
  );
};

export default Main;
