import React, { useState, useRef } from "react";
import cn from "classnames";
import styles from "./FiatAndSpot.module.sass";
import { Link } from "react-router-dom";
import Wallet from "../../components/Wallet";
import Main from "./Main";
import Funds from "../../components/Funds";
import Modal from "../../components/Modal";
import Withdraw from "../../components/Withdraw";
import Catalog from "./Catalog";
import News from "../../components/News";

const FiatAndSpot = () => {
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);

  const scrollToRefCatalog = useRef(null);

  return (
    <>
      <Wallet>
        <Main />
        <div className={styles.list}>
          <div className={styles.item}>
            <Catalog scrollToRefCatalog={scrollToRefCatalog} />
          </div>
        </div>
      </Wallet>
      <Modal
        visible={visibleWithdraw}
        onClose={() => setVisibleWithdraw(false)}
      >
        <Withdraw />
      </Modal>
    </>
  );
};

export default FiatAndSpot;
