import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Main.module.sass";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Player from "../../../components/Player";

const breadcrumbs = [
  {
    title: "Project List",
    url: "/projects",
  },
  {
    title: "TNB Solar panel",
    url: "/project-details",
  },
];

const Main = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>
            TNB 4.5KW Solar Panel Donation
          </h2>
          <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
          <Link
            to="/project-contribution"
            className={cn("button-stroke button-small", styles.button)}
          >
            <span>Contribute RM10,000</span>
          </Link>
        </div>
        <Player
          className={styles.player}
          image="/images/projects/solarvide.jpg"
        />
      </div>
    </div>
  );
};

export default Main;
