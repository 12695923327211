import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Cards.module.sass";

const items = [
  {
    title: "Cash Asset",
    price: "100,000",
    money: "USD",
    positive: "+1.19%",
    image: "images/icons/money.png",
    url: "/",
  },
  {
    title: "Crypto Asset",
    price: "0.005",
    money: "BTC",
    positive: "+0.19%",
    image: "images/icons/bitcoin.png",
    url: "/",
  },
  {
    title: "Vehicle Asset",
    price: "43",
    money: "Units",
    positive: "+2.40%",
    image: "images/icons/vehicles.png",
    url: "/",
  },
  {
    title: "Real Estate Asset",
    price: "1",
    money: "Units",
    positive: "+0.00%",
    image: "images/icons/sold.png",
    url: "/",
  },
];

const Cards = ({ className }) => {
  return (
    <>
      <div className={cn(className, styles.cards)}>
        {items.map((x, index) => (
          <Link className={styles.card} key={index} to={x.url}>
            <div className={styles.icon}>
              <img src={x.image} alt="Currency" />
            </div>
            <div className={styles.details}>
              <div className={styles.line}>
                <div className={styles.title}>{x.title}</div>
                {x.positive && (
                  <div className={styles.positive}>{x.positive}</div>
                )}
                {x.negative && (
                  <div className={styles.negative}>{x.negative}</div>
                )}
              </div>
              <div className={styles.price}>{x.price}</div>
              <div className={styles.money}>{x.money}</div>
            </div>
          </Link>
        ))}
      </div>
      <div className={styles.text}>
        <p>updated as {new Date().toLocaleString()}</p>
      </div>
    </>
  );
};

export default Cards;
